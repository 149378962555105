
import { defineComponent, ref, onMounted } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import axios from "axios";
import AddTagsModal from "@/components/modals/forms/AddTagsModal.vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { useRouter } from "vue-router";
import {active} from "@/core/data/genericData";
import { ActionsRe } from "@/store/enums/StoreResearchEnums";
import { formatDate } from "@fullcalendar/common";

export default defineComponent({
  name: "add-product-modal",
  components: {
    AddTagsModal
    },
  methods: {
    onChange() 
    { 
      this.formData.child_cat_select = '' 
      this.formData.child_select = ''
    },
    onChild1Change()
    {
      this.formData.child_select = ''
    },
    onParentClick()
    {
      this.formData.parent_select = ''
      
    },
    onChild2Change(){
     this.formData.child3_select = ''
    }
  },


  setup() {

    const active_list_data = active;
    const formRef = ref<null | HTMLFormElement>(null);
    const addProductModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const store = useStore();
    const router = useRouter(); 
    const branch = ref([]);
    const company = ref([]);

    

    // const OnClickCompany = async (compid) => {
    //   try {
    //         var values = { "company_id"  : compid, "page"  : 1, "records_per_page" : 10 }
    //         await store.dispatch(Actions.CUST_GET_BRANCH_LIST, values).then(({ data }) => {
    //         branch.value = data.result_list;
    //         console.log(branch.value)
    //     })
    //     .catch(({ response }) => {
    //       console.log(response);        
    //     });
        
    //   } catch (e) {
    //     console.log(e);
    //   }
    // }

    // const productdata = ref([]);
    // const setProductDropdown = async (data) => {
    //   const db_data = {}
    //     //loading.value = true;
    //     try {
    //         var values = {"search_term" : data}
    //         await store.dispatch(Actions.CUST_GET_PRODUCT_SELECTBOX, values).then(({ data }) => {
    //         productdata.value = data;
    //         //loading.value = false;
    //         console.log(productdata.value)
    //     })
    //     .catch(({ response }) => {
    //       console.log(response);        
    //     });
        
    //   } catch (e) {
    //     console.log(e);
    //   }

    // }

    // const compseller = ref([]);
    // const setCompanySellerdata = async (data) => {
    //   const db_data = {}
    //     loading.value = true;
    //     try {
    //         var values = { "company_id"  : 0, "search_term" : data, "page"  : 1, "records_per_page" : 10 }
    //         await store.dispatch(Actions.CUST_GET_COMPANY_LIST, values).then(({ data }) => {
    //         compseller.value = data.result_list;
    //         loading.value = false;
    //         console.log(compseller.value)
    //     })
    //     .catch(({ response }) => {
    //       console.log(response);        
    //     });
        
    //   } catch (e) {
    //     console.log(e);
    //   }

    // }

     const setCompanyData = async (data) => {
      const db_data = {}
        //loading.value = true;
        try {
            var values = { "company_id"  : 0, "search_term" : data, "page"  : 1, "records_per_page" : 10 }
            await store.dispatch(Actions.CUST_GET_COMPANY_LIST, values).then(({ data }) => {
            company.value = data.result_list;
            //loading.value = false;
            console.log(company.value)
        })
        .catch(({ response }) => {
          console.log(response);        
        });
        
      } catch (e) {
        console.log(e);
      }

    }

    //const shape = ref([]);

    // const setShapedata = async (data) => {
    //   const db_data = {}
    //     loading.value = true;
    //     try {
    //       await store.dispatch(Actions.CUST_GET_PRODUCT_SHAPE, db_data).then(({ body }) => {
    //       shape.value = body.shape_list;
    //       //loading.value = false;
    //     })
    //     .catch(({ response }) => {
    //       console.log(response);        
    //     });
        
    //   } catch (e) {
    //     console.log(e);
    //   }

    // }
    
    const child_id = ref([]);
    const child_id1 = ref([]);
    const child_id2 = ref([]);
    const child_id3 = ref([]);


    const parent_category_list = ref([]);
    const child1_category_list = ref([]);
    const child2_category_list = ref([]);
    const child3_category_list = ref([]);
    
    const setParentData = async () => {
        loading.value = true;
        
        try {
          var values = {
            "category_id" : 0,
            "mode" : "buy"}
          await store.dispatch(ActionsRe.MM_CATEGORY_LIST, values).then(({ data }) => {
          parent_category_list.value = data;
          loading.value = false;
         
        })
        .catch(({ response }) => {
          console.log(response);        
        });
        
      } catch (e) {
        console.log(e);
      }

    }

    const setParent1tData1 = async (is_next_child_present) => {
        try {
          child_id.value = is_next_child_present
      } catch (e) {
        console.log(e);
      }

    }

    const setChild1tData = async () => {
        loading.value = true;
        try {
          
          var values = {
            "category_id" : formData.value.parent_select,
            "mode" : "buy"}
          await store.dispatch(ActionsRe.MM_CATEGORY_LIST, values).then(({ data }) => {
          child1_category_list.value = data;
          loading.value = false;
        })
        .catch(({ response }) => {
          console.log(response);        
        });
        
      } catch (e) {
        console.log(e);
      }

    }

    const setChild1tData1 = async (is_next_child_present) => {
        try {
        
          child_id1.value = is_next_child_present
         
      } catch (e) {
        console.log(e);
      }

    }

    const setChild2tData = async () => {
        loading.value = true;
        try {
          var values = {
            "category_id" : formData.value.child_cat_select,
            "mode" : "buy"}
          await store.dispatch(ActionsRe.MM_CATEGORY_LIST, values).then(({ data }) => {
          child2_category_list.value = data;
          loading.value = false;
          
        })
        .catch(({ response }) => {
          console.log(response);        
        });
        
      } catch (e) {
        console.log(e);
      }

    }

    const setChild2tData2 = async (is_next_child_present) => {
        try {
        
          child_id2.value = is_next_child_present
         
      } catch (e) {
        console.log(e);
      }

    }

    const setChild3tData = async () => {
        loading.value = true;
        try {
          var values = {
            "category_id" : formData.value.child_select,
            "mode" : "buy"}
          await store.dispatch(ActionsRe.MM_CATEGORY_LIST, values).then(({ data }) => {
          child3_category_list.value = data;
          loading.value = false;
          
        })
        .catch(({ response }) => {
          console.log(response);        
        });
        
      } catch (e) {
        console.log(e);
      }

    }

    

    const making = ref([]);
    const setMakingdata = async (param) => {
      const db_data = {}
        //loading.value = true;
        try {
            await store.dispatch(Actions.CUST_GET_PRODUCT_MAKING, param).then(({ body }) => {
            making.value = body.making_process_list;
            //loading.value = false;
        })
        .catch(({ response }) => {
          console.log(response);        
        });
        
      } catch (e) {
        console.log(e);
      }

    }

    const comp = ref([]);
    const setCompositiondata = async (param) => {
      const db_data = {}
        //loading.value = true;
        try {
            await store.dispatch(Actions.CUST_GET_PRODUCT_COMPOSITION, param).then(({ body }) => {
            comp.value = body.composition_types_list;
            //loading.value = false;
        })
        .catch(({ response }) => {
          console.log(response);        
        });
        
      } catch (e) {
        console.log(e);
      }

    }

    const brand = ref([]);
    const setBranddata = async (data) => {
        //loading.value = true;
        try {
            var values = {"search_term" : data}
            await store.dispatch(Actions.CUST_PRODUCT_BRAND, values).then(({ data }) => {
            brand.value = data;
            //loading.value = false;
        })
        .catch(({ response }) => {
          console.log(response);        
        });
        
      } catch (e) {
        console.log(e);
      }
      
    }

    const formData = ref({
      name: "",    
      company_select: "", 
      sellercomp_select : "", 
      //branch_select: "",  
      child_cat_select :"",
      child_select: "",
      child3_select: "",
      brand_select:"", 
      description: "",
      parent_select: "",
      making_select: "",
      composition_select: "",
    });    

    const rules = ref({
      name: [
        {
          required: true,
          message: "Product Name is required",
          trigger: "change",  
          //pattern:"^[a-zA-Z]+$",        
        },        
      ],    
      company_select: [
        {
          required: true,
          message: "Company is required",
          trigger: "change",  
        }
      ],
      parent_select:[{
          required: true,
          message: "Category is required",
          trigger: "change",  
        }
      ],
      child_select:[{
          required: true,
          message: "Category is required",
          trigger: "change",  
        }
      ],
      child3_select:[{
          required: true,
          message: "Category is required",
          trigger: "change",  
        }
      ],
      child_cat_select:[{
          required: true,
          message: "Category is required",
          trigger: "change",  
        }
      ],
      making_select:[{
          required: true,
          message: "Making process is required",
          trigger: "change",  
        }
      ],
      composition_select: [
        {
          required: true,
          message: "Composition is required",
          trigger: "change",  
        }
      ],
       description: [
        {
          required: true,
          message: "Description is required",
          trigger: "change",  
        }
      ],
       short_name: [
        {
          required: true,
          message: "Short name is required",
          trigger: "change",  
        }
      ],
    });

    const setProductData = async (data) => {

      const db_data = {
        "product_id" : 0,
        "company_id" : data.company_select,
        "parent_product_id" : "NA",
        "parent_company_id" : "NA",
        "company_trade_type_id" : 1,
        //"branch_id" : data.branch_select,
        "brand_id" : data.brand_select,
        "product_name": "NA",
        "product_description": "NA",
        "product_short_name": "NA",
        "category_id": data.child3_select || data.child_select || data.child_cat_select || data.parent_select,    
        "making_process_id": data.making_select,
        "composition_id": data.composition_select,
        "created_user_id": "1",
        // "created_ip": "0.0.0.0",
        // "created_uagent": "Test",
        "active": true,
        }

        await store.dispatch(Actions.CUST_ADD_PRODUCT, db_data).then(({ data }) => {
          if (data){
        
            setTimeout(() => {
              loading.value = false;
              Swal.fire({
                text: "Product has been successfully created.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                hideModal(addProductModalRef.value);  
                router.go(0)           
              });
            }, 2000)          

          } else {

            loading.value = false;

            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            })  
            return false;
          }
      
      })
      .catch(({ response }) => {
        console.log(response);        
      });

    }
   
    const submit = async () => {

      loading.value = true;

      if (!formRef.value) {
        loading.value = false;
        return;
      }

      formRef.value.validate (async (valid) => {

        if (valid) {

          await setProductData(formData.value);
        
        } else {

          loading.value = false;
          return false;
        }

      });    

    };

    
    onMounted( async () => {
      //await setParentData()
      await setBranddata(brand.value)
      //await setProductDropdown("")
      await setMakingdata(making.value)
      //await setCompanySellerdata(compseller.value)
      await setCompositiondata(comp.value)
      await setCompanyData(company.value)
      //await OnClickCompany(branch.value)
    });
    return {      
      formData,      
      rules,
      submit,
      formRef,
      loading,
      addProductModalRef,
      setMakingdata,
      setCompositiondata,
      setCompanyData,
      setBranddata,
      setParentData,
      setChild1tData,
      setChild1tData1,
      setChild2tData,
      setChild2tData2,
      setChild3tData,
      setParent1tData1,
      child_id,
      child_id1,
      child_id2,
      child_id3,
      parent_category_list,
      child1_category_list,
      child2_category_list,
      child3_category_list,
      //OnClickCompany,
      //setCompanySellerdata,
      //setProductDropdown,
      active_list_data,
      //productdata,
      //compseller,
      company,
      branch,
      brand,
      comp,
      making,
      parent,
    };
  },
});
