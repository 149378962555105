

import { defineComponent, ref, onMounted, computed, onUpdated } from "vue";
import ExportCustomerModal from "@/components/modals/forms/ExportCustomerModal.vue";
import ProductsModal from "@/components/modals/forms/ProductsModal.vue";
import AddProductAttributeModal from "@/components/modals/forms/AddProductAttributeModal.vue";
import AddProductInventoryModal from "@/components/modals/forms/AddProductInventoryModal.vue";
import EditProductCategoriesModal from "@/components/modals/forms/EditProductCategoriesModal.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { Modal } from "bootstrap";


export default defineComponent({

  name: "apps-product-listing",
  components: {
    ExportCustomerModal,
    ProductsModal, 
    //AddProductAttributeModal,
    //AddProductInventoryModal,
    EditProductCategoriesModal,
  },

  setup() {
    const branch = ref([]);
    const count = ref(0)
    const store = useStore();
    const checkedCompany = ref([]);    
    const loadingData = ref<boolean>(true);
    const searchTearm = ref('');
    interface Product {
      product_id: number,      
      
      active: {
        label: string;
        color: string;
      },   
      product_name: string,   
      product_short_name: string,
      composition_name: string,
      category_name: string,
      brand_name : string,
      //making_process_name: string,
      
    }

    const product_attr_modal = (product_id) => {
      const modal = new Modal(document.getElementById("kt_product_modal_attribute"));
      modal.show();
      (document.querySelector('#product_attr_id') as HTMLInputElement).value = product_id;
    }

    const inventory_modal = async(product_id) => {
      const modal = new Modal(document.getElementById("kt_modal_add_inventory"));
      (document.querySelector('#inventory_id') as HTMLInputElement).value = product_id;
      
     // var values = { "product_id"  : product_id  }
            //await store.dispatch(Actions.CUST_SELLER_BRANCH_LIST, values).then(({ data }) => {
           // branch.value = data;
            //console.log("BranchingggggggggggggggggData")
            //console.log(branch.value)
           //(document.querySelector('#branch_select') as HTMLInputElement).append(new Option("option text", "value"));         

           // for (let j = 0; j < branch.value.length; j++)
           // {
             // branch.value[j]['branch_id']
             // branch.value[j]['branch_name']
            //  console.log("11111111111111111111111")
              //console.log(j);
             // console.log(branch.value[j]['branch_id']);
             // console.log(branch.value[j]['branch_name']);
              //(document.getElementById('#branch_select') as HTMLElement).append("pp1");         
              //e.options[j].text = branch.value[j]['branch_name'];
           // }
        //});
        modal.show();
      //(document.querySelector('#inventory_id') as HTMLInputElement).value = product_id;
    }

    var paginationData = ref({})
    
    paginationData.value = {      
        start : 0,
        end : 0,
        total: 0,        
        perPage : 5,
        activePage : 1,
        totPage : 1,        
        pageRange : [1]
    }

    const refreshData =() => {      
      paginationData.value['activePage']  = 1;      
      getProduct(searchTearm.value)
    }

    const changePageChange = (page) => {      
      paginationData.value['activePage']  = page;      
      getProduct(searchTearm.value)
    }
    
    var tableData = ref<Array<Product>>([]);
     const getProduct = async (data) => {
      loadingData.value = true;

      try {
        var values = { "search_term" : data,"product_id":  0, "page"  : parseInt(paginationData.value['activePage']), "records_per_page" : parseInt(paginationData.value['perPage']) }
        await store.dispatch(Actions.CUST_GET_PRODUCT_LIST, values).then(({ data }) => {
          console.log(data);
          tableData.value = ([]);

          // set pagination
          paginationData.value['total'] = data.total_records;        
          paginationData.value['start'] = data.records_from
          paginationData.value['end'] = data.records_upto
          paginationData.value['activePage'] = data.page;
          paginationData.value['totPage']  = data.total_pages
          paginationData.value['pageRange'] = []
          for (let index =  data.bar_range_start; index < data.bar_range_end; index++) {
            paginationData.value['pageRange'].push(index)
          }
          //endpagination
          
          var resultsM = ref<Array<Product>>([])
          var status_label = ""
          var status_color = ""
          var active_label = ""
          var active_color = ""        

          for (let j = 0; j < data.result_list.length; j++) {

             if (data.result_list[j]['active']){
                active_label = "Yes";
                active_color = "success";                  
            }else{
                active_label = "No";
                active_color = "danger";
            }

            if (data.result_list[j]['active']){
                active_label = "Yes";
                active_color = "success";                  
            }else{
                active_label = "No";
                active_color = "danger";
            }
            
            resultsM.value = Array({
              product_id : data.result_list[j]['product_id'],
              
              active: {
                label: active_label,
                color: active_color
              },
              product_name: data.result_list[j]['product_name'],
              product_short_name: data.result_list[j]['product_short_name'],
              brand_name : data.result_list[j]['brand_name'],
              composition_name: data.result_list[j]['composition_name'],
              category_name: data.result_list[j]['category_name'],
              //making_process_name: data.result_list[j]['making_process_name'],

            })
            console.log("Result")
            console.log(resultsM.value)
            tableData.value.splice(j, resultsM.value.length, ...resultsM.value);      
            console.log("Table")
            console.log(tableData.value)
          }


          loadingData.value = false;

        }).catch(({ response }) => {
          console.log("catch")
          tableData.value = [];
          loadingData.value = false;

       });

      } catch (e) {
        console.log(e);
      }
    };

      onUpdated(() => {          
        // console.log("aaap");
        // console.log(list_data);
        
      });

      const initCustomers = ref<Array<Product>>([]);                      

      onMounted( async () => {
        await getProduct(searchTearm.value)
        console.log(tableData.value)
        setCurrentPageBreadcrumbs("Products", []);
        initCustomers.value.splice(0, tableData.value.length, ...tableData.value);        
        console.log(tableData.value.length);
      });  

      const deleteFewCustomers = () => {
        checkedCompany.value.forEach((item) => {
          deleteCustomer(item);
        });
        checkedCompany.value.length = 0;
      };

      const deleteCustomer = (id) => {
        for (let i = 0; i < tableData.value.length; i++) {
          if (tableData.value[i].product_id === id) {
            tableData.value.splice(i, 1);
          }
        }
      };

      const search = ref<string>("");
       const searchItems = () => {
          searchTearm.value = search.value;
          refreshData();
          getProduct(search.value)
          console.log(search.value);
          
      };

      const searchingFunc = (obj, value): boolean => {        
        for (let key in obj) {          
          if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {            
            if (obj[key].indexOf(value) != -1) {
              
              return true;
            }
          }
        }
        return false;
      };          
       
    return {
      tableData,      
      deleteCustomer,
      search,
      searchItems,
      checkedCompany,
      deleteFewCustomers,
      count,  
      product_attr_modal,
      inventory_modal,   
      getProduct,       
      paginationData,
      changePageChange,
      refreshData,
      //setBranch,
      branch,
      loadingData,
    };  

  }  

});




